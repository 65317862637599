import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import UnitSkuStockInput from './UnitSkuStockInput';
import Loading from '../Loading';
import Modal from '../Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import InventoryConstants from './InventoryConstants';
import ComponentStockStore from "../../stores/ComponentStockStore";
import SubComponentStockInput from './SubComponentStockInput';

const base = 'sku-entry';

@inject('ComponentStockStore')
@observer
class InventoryComponentStockCountUpdate extends Component {
    initialState = {
        openSkuList: false,
        isResubmit: false,
        showConfirmationModel: false,
        stockQuantity: {},
        errorMessage: '',
        currentSku: '',
        stockQuantityPerPackaging: {},
        showErrorModel: false,
        incorrectQuantityMessage: '',
        hasInput: false,
        lastSku: '',
        submitterName: ''
    };

    state = this.initialState;

    componentDidMount = () => {
        const {
            match: {
                params: { shift },
            },
            ComponentStockStore: {
                handleGetUnitBatchesForDailyStockCheck,
                handleGetUnitSkus,
            }
        } = this.props;

        handleGetUnitSkus(handleGetUnitBatchesForDailyStockCheck);
        this.setState({ shift: shift });
    };

    getInitialSku = () => {
        const {
            ComponentStockStore: {
                unitSkuList
            }
        } = this.props;
        let { lastSku } = this.state;

        const keys = Object.keys(unitSkuList);
        const currentIndex = keys.indexOf(lastSku);

        if (currentIndex !== -1 && currentIndex < keys.length - 1) {
            for (var i = currentIndex + 1; i < keys.length; i++) {
                const batch = unitSkuList[keys[i]];
                if(batch.is_sub_component){
                    continue;
                }
                if (!batch[InventoryConstants.FINAL_CHECK_COMPLETED]) {
                    return keys[i]
                }
            }
        } else {
            for (const sku of keys) {
                const batch = unitSkuList[sku];
                if(batch.is_sub_component){
                    continue;
                }
                if (!batch[InventoryConstants.FINAL_CHECK_COMPLETED]) return sku;
            }
            return keys[0];
        }
    }

    handleOpenSkuList = () => {
        this.setState({ openSkuList: true });
    };

    handleCloseSkuList = () => {
        this.setState({ openSkuList: false });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModel: false, showErrorModel: false });
    };

    handleSetCurrentSku = (sku, batch) => {
        this.setState({ currentSku: sku, quantity: batch.unsold + batch.pre_order_quantity, openSkuList: false, hasInput: false });
        this.handleSetStockQuantityPerPackaging(sku);
    };

    handleChangeSubmitterName = (e) => {
        const { name, value } = e.target;
        this.setState({ submitterName: value });
    }

    handleSetStockQuantityPerPackaging = (sku) => {
        const { ComponentStockStore: { unitSkuList } } = this.props;
        const skuList = unitSkuList;
        let packaging = ComponentStockStore.getPackaging({sku: sku});
        let updatedStockQuantityPerPackaging = {};
        updatedStockQuantityPerPackaging[sku] = packaging;
        if (skuList[sku].sub_component) {
            skuList[sku].sub_component.forEach((subComponent) => {
                if (!subComponent.expire_sub_component_at_night) {
                    packaging = ComponentStockStore.getPackaging({sku: subComponent.unit_sku});
                    updatedStockQuantityPerPackaging[subComponent.unit_sku] = packaging;
                }
            })
        }
        this.setState({ stockQuantityPerPackaging: updatedStockQuantityPerPackaging });
        return updatedStockQuantityPerPackaging;
    }

    handleChangeStockInputs = (sku, quantity, quantityPerPackaging) => {
        let { currentSku } = this.state;
        const { ComponentStockStore: { unitSkuList } } = this.props;
        const skuList = unitSkuList;
        let { stockQuantity, stockQuantityPerPackaging } = this.state;
        let updatedStockQuantity = {};
        let addedComponent = '';

        if (!currentSku) {
            currentSku = this.getInitialSku();
            this.handleSetCurrentSku(currentSku, unitSkuList[currentSku]);
            stockQuantityPerPackaging = this.handleSetStockQuantityPerPackaging(currentSku);
        }

        if (Object.keys(stockQuantity).length === 0) {
            if (skuList[sku].is_sub_component) {
                addedComponent = skuList[sku].parent_component_sku;
            } else {
                addedComponent = sku;
            }
        } else {
            const key = Object.keys(stockQuantity)[0];
            if (skuList[key].is_sub_component) {
                addedComponent = skuList[key].parent_component_sku;
            } else {
                addedComponent = key;
            }
        }

        if (currentSku && currentSku !== addedComponent) {
            updatedStockQuantity = {
                [sku]: quantity,
            };
        } else {
            updatedStockQuantity = {
                ...stockQuantity,
                [sku]: quantity,
            };
        }

        const updatedStockQuantityPerPackaging = {
            ...stockQuantityPerPackaging,
            [sku]: quantityPerPackaging,
        };

        const hasMissingQuantity = Object.values(updatedStockQuantityPerPackaging)
        .flat()
        .some(item => item && (item.quantity === undefined || isNaN(item.quantity)));
        
        this.setState({ stockQuantity: updatedStockQuantity, stockQuantityPerPackaging: updatedStockQuantityPerPackaging, hasInput: !hasMissingQuantity});
    };

    handleSubmit = () => {
        const { stockQuantity, isResubmit } = this.state;
        let { currentSku } = this.state;

        if (!currentSku) {
            currentSku = this.getInitialSku();
        }
        if (this.isSkuChecked(currentSku) && !isResubmit) {
            this.setState({ isResubmit: true });
        } else {
            let pathname = window.location.href.split('/');
            if (pathname[pathname.length -1] === "initial"){
                alert('Please use final stock check');
                return;
            }

            const { ComponentStockStore: { unitSkuList, getBasePackaging } } = this.props;
            const skuList = unitSkuList;
            let totalVirtualCount = 0;
            let totalInputQuantity = 0;
            let incorrectQuantityMessage = [];

            let parentComponent = skuList[currentSku];
            if (parentComponent) {
                totalVirtualCount = totalVirtualCount + parentComponent.virtual_stock;
                totalInputQuantity = totalInputQuantity + stockQuantity[currentSku] ? stockQuantity[currentSku] : 0;
            }
            if (parentComponent.sub_component) {
                parentComponent.sub_component.forEach((subComponent) => {
                    totalVirtualCount = totalVirtualCount + (subComponent.virtual_stock / subComponent.breakdown_quantity);
                    if (stockQuantity[subComponent.unit_sku]) {
                        totalInputQuantity = totalInputQuantity + (stockQuantity[subComponent.unit_sku] / subComponent.breakdown_quantity);
                    }
                })
            }
            if (totalVirtualCount > 0) {
                const baseUom = getBasePackaging({"sku": currentSku});
                if (totalInputQuantity == 0) {
                    // Do nothing for input = 0 case

                    // if (totalVirtualCount >= 10) {
                    //     incorrectQuantityMessage.push({
                    //         unitSku: currentSku,
                    //         count: totalInputQuantity,
                    //         virtualStock: totalVirtualCount,
                    //         baseUom
                    //     });
                    // }
                } else if (totalInputQuantity / totalVirtualCount >= 10 || totalInputQuantity / totalVirtualCount <= 0.1) {
                    incorrectQuantityMessage.push({
                        unitSku: currentSku,
                        count: totalInputQuantity,
                        virtualStock: totalVirtualCount,
                        baseUom
                    });
                }
            }
            if (incorrectQuantityMessage.length) {
                this.setState({ showErrorModel: true, incorrectQuantityMessage });
                return;
            }

            let errorMessage = Object.entries(stockQuantity).reduce(
                (acc, [unitSku, quantity]) => {
                    const batch = skuList[unitSku];
                    const batchDates = `null - null`;
                    const baseUom = getBasePackaging({"sku": unitSku});
                    const countDifference = Math.abs(quantity - batch.virtual_stock);
                    if (
                        quantity &&
                        countDifference > 0
                    ) {
                        if (batch.virtual_stock > 0) {
                            if ((countDifference / batch.virtual_stock) * 100 > 9) {
                                acc.push({
                                    unitSku,
                                    count: quantity,
                                    virtualStock: batch.virtual_stock,
                                    batchDates,
                                    baseUom
                                });
                            }
                        } else {
                            acc.push({
                                unitSku,
                                count: quantity,
                                virtualStock: batch.virtual_stock,
                                batchDates,
                                baseUom
                            });
                        }
                    }
                    return acc;
                },
                []
            );

            if (errorMessage.length) {
                this.setState({ showConfirmationModel: true, errorMessage });
            } else {
                this.handleSendRequest();
            }
        }
    };

    handleSendRequest = async () => {
        const { stockQuantity, submitterName } = this.state;
        const {
            ComponentStockStore: { handleBulkUpdateUnitBatch, unitSkuList },
        } = this.props;

        for (const sku of Object.keys(stockQuantity)) {
            if (stockQuantity[sku] > 10000){
                alert('The quantities added for ' + sku + ' do not seem right. Please alert the tech team if this is not the case.');
                return;
            }
        }
        const skuList = unitSkuList;
        let requests = [];
        let lastSku = '';

        for(let sku of Object.keys(stockQuantity)){
            if (skuList[sku].is_sub_component){
                let parentComponentSku = skuList[sku].parent_component_sku;
                if (!(parentComponentSku in stockQuantity)) {
                    stockQuantity[parentComponentSku] = skuList[parentComponentSku].virtual_stock
                    sku = parentComponentSku
                }
            }

            if (skuList[sku].is_sub_component) {
                lastSku = skuList[sku].parent_component_sku;
            } else {
                lastSku = sku;
            }

            let subComponentRequest = [];
            if(!skuList[sku].is_sub_component){
                if(skuList[sku].sub_component){
                    skuList[sku].sub_component.map((subComponent) => {
                        if (!subComponent.expire_sub_component_at_night) {
                            subComponentRequest.push({
                                unit_sku: subComponent.unit_sku,
                                quantity: stockQuantity[subComponent.unit_sku],
                                delta : skuList[subComponent.unit_sku] ?
                                    stockQuantity[subComponent.unit_sku] - skuList[subComponent.unit_sku].virtual_stock : 0
                            })
                        }
                })}
                let request = {
                    unitSku: sku,
                    quantity: stockQuantity[sku],
                    delta : skuList[sku] ? stockQuantity[sku] - skuList[sku].virtual_stock : 0,
                    subComponentUnitUpdateRequest: subComponentRequest,
                    submitter: submitterName
                }
                requests.push(request)
            }
        }

        await handleBulkUpdateUnitBatch(requests);
        this.setState({
            lastSku: lastSku,
            openSkuList: false,
            isResubmit: false,
            showConfirmationModel: false,
            stockQuantity: {},
            errorMessage: '',
            currentSku: '',
            stockQuantityPerPackaging: {},
            showErrorModel: false,
            incorrectQuantityMessage: '',
            hasInput: false,
        })
    };

    isSkuChecked = (currentSku) => {
        const {
            ComponentStockStore: {
                unitSkuList
            }
        } = this.props;

        if (!unitSkuList) {
            return false;
        }
        const batch = unitSkuList[currentSku];
        return batch[InventoryConstants.FINAL_CHECK_COMPLETED];
    }

    getTotalSkuChecked = () => {
        const {
            ComponentStockStore: {
                unitSkuList
            }
        } = this.props;
        if (!unitSkuList) {
            return 0;
        }

        return Object.keys(unitSkuList).reduce(
            (total, sku) => (this.isSkuChecked(sku) && !unitSkuList[sku].is_sub_component ? total + 1 : total),
            0
        );
    }

    getTotalStockText = () => {
        const { currentSku, stockQuantityPerPackaging } = this.state;
        const { ComponentStockStore: { unitSkuList } } = this.props;
        if (!currentSku || !stockQuantityPerPackaging[currentSku]) {
            return '';
        }

        let totalQuantity = 0;
        let minUoMQty = 100000;
        let minUoM = 'meals';
        for (const packaging of stockQuantityPerPackaging[currentSku]) {
            if (packaging.innerQuantity < minUoMQty) {
                minUoMQty = packaging.innerQuantity;
                minUoM = packaging.packaging;
            }
            if (!packaging.quantity) {
                continue;
            }
            totalQuantity += packaging.quantity * packaging.innerQuantity;
        }

        if (unitSkuList[currentSku] && unitSkuList[currentSku].sub_component) {
            let minBreakdownQty = 100000;
            let minSubComponentSku = '';
            for (const subComponent of unitSkuList[currentSku].sub_component) {
                if (subComponent.breakdown_quantity < minBreakdownQty && stockQuantityPerPackaging[subComponent.unit_sku]) {
                    minBreakdownQty = subComponent.breakdown_quantity;
                    minSubComponentSku = subComponent.unit_sku;
                }
            }
            if (minSubComponentSku) {
                let totalSubComponentQuantity = 0;
                minUoMQty = 100000;
                for (const subComponent of unitSkuList[currentSku].sub_component) {
                    if (stockQuantityPerPackaging[subComponent.unit_sku]) {
                        let qty = 0;
                        for (const packaging of stockQuantityPerPackaging[subComponent.unit_sku]) {
                            if (packaging.innerQuantity < minUoMQty && subComponent.unit_sku == minSubComponentSku) {
                                minUoMQty = packaging.innerQuantity;
                                minUoM = packaging.packaging;
                            }
                            if (!packaging.quantity) {
                                continue;
                            }
                            qty += packaging.quantity * packaging.innerQuantity;
                        }
                        totalSubComponentQuantity += (qty / subComponent.breakdown_quantity * minBreakdownQty);
                    }
                }
                totalQuantity = totalQuantity * minBreakdownQty + totalSubComponentQuantity;
            }
        }
        return totalQuantity + ' ' + minUoM;
    }

    render() {
        const {
            openSkuList,
            stockQuantity,
            stockQuantityPerPackaging,
            isResubmit,
            showConfirmationModel,
            errorMessage,
            showErrorModel,
            incorrectQuantityMessage,
            hasInput,
            submitterName
        } = this.state;
        let {
            currentSku
        } = this.state;
        const {
            ComponentStockStore: {
                componentStockUpdateLoading,
                unitSkuList
            },
        } = this.props;
        if (!currentSku && unitSkuList) {
            currentSku = this.getInitialSku();
        }
        if (componentStockUpdateLoading || !currentSku) {
            return <Loading />;
        }

        let itemsByCategory = {};
        const unitSkuListToShow = {}
        Object.keys(unitSkuList).map((unitSku) => {
            if(unitSkuList[unitSku].is_sub_component){
                return
            }
            unitSkuListToShow[unitSku] = unitSkuList[unitSku];
            if (!itemsByCategory[unitSkuListToShow[unitSku].category]) {
                itemsByCategory[unitSkuListToShow[unitSku].category] = [];
            }
            itemsByCategory[unitSkuListToShow[unitSku].category].push(unitSkuListToShow[unitSku]);
        });

        const totalSkus = Object.keys(unitSkuListToShow).length;
        const totalSkusChecked = this.getTotalSkuChecked();
        const isChecked = this.isSkuChecked(currentSku);
        const totalStockText = this.getTotalStockText(currentSku);

        return (
            <div className="mbxl pbxl">
                <InventoryHeader title="Stock Count" />
                <div
                    className={`${base}__progress fs--medium-medium-large text--bolder ps mbs`}
                >
                    <span
                        className={`text--red`}
                    >{`${totalSkusChecked} out of ${totalSkus} `}</span>
                    <span>SKUs completed </span>
                    <button
                        className="btn width--50 mtm"
                        onClick={this.handleOpenSkuList}
                    >
                        Jump to SKU
                    </button>
                </div>
                <div className={`pl`}>
                    <p className={`text--bolder mrs`} style={{fontSize: "20px"}}>
                        {unitSkuList[currentSku].category}
                    </p>
                    <p className={`fs--large text--bolder mrs`}>
                        {currentSku + ' : ' + unitSkuList[currentSku].name}
                    </p>
                    <p className={`fs--medium`}></p>
                </div>
                <div style={{display : 'flex', flexFlow: 'column'}}>
                    <UnitSkuStockInput
                        key={currentSku}
                        batch={unitSkuList[currentSku]}
                        isResubmit={isResubmit}
                        onChange={this.handleChangeStockInputs}
                        value={stockQuantity[currentSku] || ''}
                        quantityPerPackaging={stockQuantityPerPackaging[currentSku] || ''}
                    />
                    {unitSkuList[currentSku].sub_component && unitSkuList[currentSku].sub_component.map(entry => (
                        !entry.expire_sub_component_at_night && (
                            <SubComponentStockInput
                                key={entry.unit_sku}
                                batch={unitSkuList[entry.unit_sku]}
                                isResubmit={isResubmit}
                                onChange={this.handleChangeStockInputs}
                                value={stockQuantity[entry.unit_sku] || ''}
                                quantityPerPackaging={stockQuantityPerPackaging[entry.unit_sku] || ''}
                            />
                        )
                    ))}
                </div>
                <div style={{marginLeft: '-25%'}}>
                    <p className='fs--medium-medium-large ps'>Total Stock = {totalStockText}</p>
                </div>
                {openSkuList && (
                    <div className={`production__drawer-mask`}></div>
                )}
                <div
                    className={`production__drawer ${
                        openSkuList ? 'open' : ''
                    }`}
                >
                    <div
                        className={`production__nav-btn drawer-close`}
                        onClick={this.handleCloseSkuList}
                    >
                        <svg className="icon">
                            <use xlinkHref={`#icon-cross`} />
                        </svg>
                    </div>
                    <p className="fs--medium-large text--sofiapro text--left mbm">
                        Select SKU
                    </p>
                    <div className={`production__drawer-buttons`}>
                    {Object.keys(itemsByCategory).map(category => (
                        itemsByCategory[category].length > 0 && (
                        <Fragment key={category}>
                            <p  style={{fontSize: "20px", marginTop: '15px', marginBottom: '15px', fontFamily: 'sans-serif', fontWeight: '400'}}>{category}</p>
                            {itemsByCategory[category].map(item => (
                            <div
                                key={item.unit_sku}
                                className={`production__drawer-button ${
                                this.isSkuChecked(item.unit_sku)
                                    ? 'selected'
                                    : 'unchecked'
                                } ${
                                currentSku === item.unit_sku ? 'current' : ''
                                } font-small`}
                                onClick={() =>
                                this.handleSetCurrentSku(
                                    item.unit_sku,
                                    unitSkuListToShow[item.unit_sku]
                                )
                                }
                            >
                                {item.unit_sku + ' : ' + item.name}
                            </div>
                            ))}
                        </Fragment>)
                    ))}
                    </div>
                </div>
                <div className="footer-bottom">
                    <div style={{margin: 'auto'}}>
                        <p className="fs--medium-medium-large ps text--sofiapro display--inline-block" style={{paddingRight: '30px'}}>Your name:</p>
                        <input
                            className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--200px center--horizontal ps'
                            name='submitter-name'
                            value={submitterName}
                            onChange={this.handleChangeSubmitterName}
                            style={{marginRight: '30px'}}
                        />
                        <button
                            className={`btn width--300px ${
                                isChecked && !isResubmit ? 'btn-selected' : ''
                            }`}
                            onClick={this.handleSubmit}
                            disabled={(!hasInput && (!isChecked || isResubmit)) || !submitterName}
                        >
                            {isChecked && !isResubmit
                                ? 'Do Again'
                                : 'Submit and Next'}
                        </button>
                    </div>
                </div>
                {showErrorModel && (
                    <Modal>
                        <h3>
                            Stock count is wrong. Please count again and make sure to enter the number into the right field
                        </h3>
                        <br />
                        {incorrectQuantityMessage.map(
                            ({ unitSku, count, baseUom }) => (
                                <div className="mbm" key={unitSku}>
                                    <h3>{unitSku}: {unitSkuList[unitSku].name}</h3>
                                    <div
                                        className={`display--flex justify--space-between flex-align-items-center`}
                                    >
                                        <div>
                                            <div className="modal-content">
                                            {`Your count: ${stockQuantityPerPackaging[unitSku]
                                            .map(quantityPerPackaging => {
                                                if (quantityPerPackaging.quantity) {
                                                return `${quantityPerPackaging.quantity} ${quantityPerPackaging.packaging}`;
                                                }
                                                return null; // Ensure a value is returned for each iteration
                                            })
                                            .filter(Boolean) // Remove null values from the array
                                            .join(', ')} 
                                            ${stockQuantityPerPackaging[unitSku].filter(item => item.quantity).length == 1 ? '' : ' = ' + count + ' ' + baseUom}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        <div className="display--flex justify--space-around">
                            <button
                                className="btn ms"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Count again
                            </button>
                        </div>
                    </Modal>
                )}
                {showConfirmationModel && (
                    <Modal>
                        <h3>
                            Attention! Big difference in count, are you sure
                            your count is correct? <br />
                            If you save the count, an email will be sent to your supervisor about the inventory mismatch
                        </h3>
                        <br />
                        {errorMessage.map(
                            ({ unitSku, count, virtualStock, batchDates, baseUom }) => (
                                <div className="mbm" key={unitSku}>
                                    <h3>{unitSku}: {unitSkuList[unitSku].name}</h3>
                                    <div
                                        className={`display--flex justify--space-between flex-align-items-center`}
                                    >
                                        <div>
                                            <div className="modal-content">
                                            {`Your count: ${stockQuantityPerPackaging[unitSku]
                                            .map(quantityPerPackaging => {
                                                if (quantityPerPackaging.quantity) {
                                                return `${quantityPerPackaging.quantity} ${quantityPerPackaging.packaging}`;
                                                }
                                                return null; // Ensure a value is returned for each iteration
                                            })
                                            .filter(Boolean) // Remove null values from the array
                                            .join(', ')} 
                                            ${stockQuantityPerPackaging[unitSku].filter(item => item.quantity).length == 1 ? '' : ' = ' + count + ' ' + baseUom}`}
                                            </div>
                                        </div>
                                        {count === '0' ? (
                                            <div className="width--50">
                                                You are removing stock that has
                                                already been sold. CS will be
                                                notified to cancel the orders.
                                                This will impact your HUB
                                                PERFORMANCE KPI. Are you sure?
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        )}
                        <div className="display--flex justify--space-around">
                            <button
                                className="btn ms"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Count again
                            </button>
                            <button
                                className="btn btn-primary ms"
                                onClick={this.handleSendRequest}
                            >
                                Yes, save count
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default InventoryComponentStockCountUpdate;
