import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import InventoryComponentBatchHistoryModal from './InventoryComponentBatchHistoryModal';
import ComponentStockStore from "../../stores/ComponentStockStore";
import InventoryStore from "../../stores/InventoryStore";

const base = 'sku-entry';

@inject('ComponentStockStore')
@observer
class UnitSkuStockInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantityPerPackaging: [],
        };
    }

    componentDidMount = () => {
        const {
            batch: {unit_sku},
            quantityPerPackaging
        }= this.props;
        let packaging = '';
        if (quantityPerPackaging) {
            packaging = quantityPerPackaging;
        } else {
            packaging = ComponentStockStore.getPackaging({sku: unit_sku});
        }
        this.setState({quantityPerPackaging: packaging});
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        const { name, value, dataset } = e.target;
        let {
            quantityPerPackaging
        }= this.state;
        quantityPerPackaging.filter(p => p.id === name)[0].quantity = parseInt(value);
        this.setState({quantityPerPackaging});
        onChange(dataset.sku, this.calculateUnitQuantity(), quantityPerPackaging);
    };

    calculateUnitQuantity = () => {
        const {
            quantityPerPackaging
        }= this.state;
        let totalQuantity = 0;
        for (const packaging of quantityPerPackaging) {
            if (!packaging.quantity) {
                continue;
            }
            totalQuantity += packaging.quantity * packaging.innerQuantity;
        }
        return totalQuantity;
    }

    lastUpdated = (stock_check_tracker) => {
        const stockCheckTransferLog =
            stock_check_tracker &&
            stock_check_tracker.filter((entry) => {
                const { updated_at, reason } = entry;
                return reason === 'DAILY_STOCK_CHECK' && InventoryStore.isUpdatedInTimeFrame(updated_at);
            });

        return (
            stockCheckTransferLog &&
            stockCheckTransferLog[stockCheckTransferLog.length - 1]
        );
    }

    render() {
        const {
            batch: {
                unit_sku,
                stock_check_tracker,
                isCheckCompleted,
                name
            },
            value,
            isResubmit,
        } = this.props;
        const lastUpdatedLog = this.lastUpdated(stock_check_tracker);
        const count = isResubmit
            ? value
            : isCheckCompleted
                ? lastUpdatedLog.quantity
                : value;
        const {
            quantityPerPackaging
        } = this.state;
        let isFirstPackaging = true;

        return (
            <Fragment>
                <div className={`fs--medium-large ps bg--black text--white mtl`}>
                    {unit_sku + ' : ' + name}
                </div>
                <div className={`${base} pl`} style={{paddingBottom: '0px'}}>
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <div className="grid grid-1-1">
                            <label className='fs--medium-medium-large'>Quantity</label>
                            <label className='fs--medium-medium-large text--left ps'>Packaging</label>
                            {quantityPerPackaging && quantityPerPackaging.map((p, index) => (
                                    <Fragment key={p.id}>
                                        {index > 0 && (
                                            <p className='fs--medium-medium-large text--center text--black width--300px center--horizontal ps'>+</p>
                                        )}
                                        {index > 0 && (
                                            <p className='fs--medium-medium-large text--black width--300px ps'></p>
                                        )}
                                        <input
                                            className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--200px center--horizontal ps'
                                            type='number'
                                            name={p.id}
                                            data-sku={unit_sku}
                                            min={0}
                                            value={p.quantity === undefined ? '' : p.quantity}
                                            onChange={this.handleChange}
                                        />
                                        <p className='fs--medium-medium-large text--left text--black text--sofiapro width--300px ps'>
                                            {p.packaging}
                                        </p>
                                    </Fragment>
                                ),
                            )}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <InventoryComponentBatchHistoryModal
                            history = { stock_check_tracker }
                        />
                        {lastUpdatedLog && (
                            <div className="text--left mtxl">
                                <h3>Last updated</h3>
                                <p>User: {lastUpdatedLog.initiator_username}</p>
                                <p>
                                    Time:{' '}
                                    {moment(
                                        lastUpdatedLog.updated_at,
                                        'x'
                                    ).calendar()}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default UnitSkuStockInput;
